<template>
	<div :class="['switchbar',zhuangtai?'':'nocheckbox']" @click="SetSwitch">
		<div :class="['switchbutton',zhuangtai?'':'nocheck']"></div>
	</div>

</template>
<style>
	.switchbar {
		width: 40px;
		height: 20px;
		border-radius: 10px;
		background-color: rgb(143, 195, 31);
		line-height: 20px;
		position: relative;
		float: right;
		margin-right: 10px;
		margin-top: 14px;
		
	}
	
	.switchbutton {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		background-color: rgb(255, 255, 255);
		margin-left: 2px;
		margin-top: 2px;
		-webkit-transform: translate3d(20px,0,0);
		transform: translate3d(20px,0,0);
	}
	.nocheck{
		-webkit-transform: translate3d(0px,0,0);
		transform: translate3d(0px,0,0);
		
	}
	.nocheckbox{
		background-color: #CCCCCC;
	}
</style>
<script>
	export default {
		name: 'switchbar',
		props: ["zt"],
		data() {
			return {
				zhuangtai:false
			}
		},
		watch:{
			zt:function(n,o){
				this.zhuangtai = n;
			}
		},
		computed: {},
		befo0pxount() {},
		components: {},
		mounted() {
			this.zhuangtai = this.zt;
			
		},
		methods: {
			SetSwitch($event) {
				let type = this.zhuangtai;
				let switchbox = $event.target;
				let switchbar = null;
				if($($event.target).hasClass("switchbutton")) {
					switchbar = $event.target;
					switchbox = $($event.target).parent();
					
				}else{
					switchbox = $event.target;
					switchbar = $($event.target).find(".switchbutton")
				}
				let that = this;

				if(type) {

					$(switchbox).animate({
						backgroundColor: "#ccc"
					}, 300);
					$(switchbar).animate({
						translate3d: "0px,0,0"
					}, 300, function() {
						that.zhuangtai = false;
						that.$emit("switched",that.zhuangtai)
					});

				} else {

					$(switchbox).animate({
						backgroundColor: "#8fc31f"
					}, 300);
					$(switchbar).animate({
						translate3d: "20px,0,0"
					}, 300, function() {
						that.zhuangtai = true;
						that.$emit("switched",that.zhuangtai)
					});

				}
				return;

			}
		}
	}
</script>